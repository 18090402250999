<template>
    <Dialog
        contentClass="pb-0"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '832px' }"
        header="Dados da solicitação de agendamento"
        :closable="true"
        :modal="true"
        @update:visible="$emit('onClose')"
    >
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="mt-3">
                <p class="text-lg">Funcionários</p>
            </div>

            <div v-for="agendamento in agendamentos" :key="agendamento">
                <Accordion class="mt-5 mb-4" :multiple="true" iconPos="endVal">
                    <AccordionTab>
                        <template #header>
                            <div class="flex justify-content-between w-full">
                                <span class="select-text cursor-text" @click.stop
                                    >{{ `${agendamento.agendamento.funcionario.id} - ${agendamento.agendamento.funcionario.name}` }}
                                </span>
                                <i class="pi pi-copy" @click.stop="copy(agendamento.agendamento.funcionario.name)"></i>
                            </div>
                        </template>
                        <div class="accordion-content">
                            <div class="flex flex-row">
                                <div class="col flex-1">
                                    <strong>CPF</strong><br />
                                    {{ agendamento.agendamento.funcionario.cpf }}
                                </div>
                                <div class="col flex-1">
                                    <strong>Data da solicitação</strong><br />
                                    {{ $filters.formatDate(agendamento.agendamento.createdAt) }}
                                </div>
                                <div class="col flex-1">
                                    <strong>Data prevista</strong><br />
                                    {{ $filters.formatDateOnly(agendamento.agendamento.dataSugestaoInicial) }} -
                                    {{ $filters.formatDateOnly(agendamento.agendamento.dataSugestaoFinal) }}
                                </div>
                            </div>
                            <div class="flex flex-row">
                                <div class="col flex-1">
                                    <strong>Nome Solicitante</strong><br />
                                    {{ agendamento.agendamento.nomeSolicitante }}
                                </div>
                                <div class="col flex-1">
                                    <strong>E-mail Solicitante</strong><br />
                                    {{ agendamento.agendamento.emailSolicitante }}
                                </div>
                                <div class="col flex-1">
                                    <strong>Telefone Solicitante</strong><br />
                                    {{ agendamento.agendamento.telefoneSolicitante }}
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-arrow-left" class="p-button-text" @click="$emit('onClose')" />
            <Button label="Liberar hierarquia" icon="pi pi-check" class="submit p-button-submit" @click="confirmaSubmissao" />
        </template>
    </Dialog>
</template>

<script>
import { getProfile } from '../../../../services/auth';
import BaseService from '../../../../services/BaseService';
import SecurityService from '@/services/SecurityService';

export default {
    props: ['liberaHierarquia'],
    data() {
        return {
            agendamentos: this.liberaHierarquia,
            loading: false,
            hierarquia: null,
            respostas: []
        };
    },
    created() {
        this.$serviceHierarchy = new BaseService('/libera-hierarquia/liberar-lista');
        this.$serviceLiberarHierarquia = new SecurityService('libera-hierarquia');
    },
    watch: {
        liberaHierarquia(newVal) {
            this.agendamentos = newVal;
        }
    },
    methods: {
        async confirmaSubmissao() {
            const responsavel = await getProfile();

            const erroFuncionarios = [];

            for (const agendamento of this.agendamentos) {
                try {
                    agendamento.userUpdateId = responsavel.id;
                    await this.$serviceLiberarHierarquia.savePatch(agendamento);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: `Hierarquia ${agendamento.id} liberada com sucesso`,
                        life: 3000
                    });
                } catch (err) {
                    const mensagem = err?.response?.data?.message;
                    if (!erroFuncionarios.includes(agendamento.agendamento.funcionario.id)) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Falha ao liberar hierarquia',
                            detail: mensagem,
                            life: 5000
                        });
                        erroFuncionarios.push(agendamento.agendamento.funcionario.id);
                    }
                }
            }
        },
        copy(text) {
            navigator?.clipboard?.writeText?.(text);
            this.$toast.add({
                severity: 'success',
                summary: 'Nome do funcionario copiado',
                life: 2000
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.fading-btn {
    opacity: 0;
    transition: all 1.4s ease;
    margin: 0.1em;
}
.fading-btn:hover {
    opacity: 1;
}
</style>
